import type {
    Expense,
    FTE,
    Instance,
    InternetEgress,
    MiscellaneousCosts,
    Server,
} from '../shared/types';
import { EXPENSE_TYPE, RECURRING } from '../shared/types.ts';
import type {
    VirtualMachineComparisonTemplate,
    CloudEgressExpense,
} from './types.ts';

export const instancesData: Instance[] = [
    {
        name: 'Linux VMs', // fixed, can't be renamed
        quantity: '168',
        growth: '0',
        vcpu: '4',
        memory: '8',
        dataEgress: '5000',
        storage: '3800',
    },
    {
        name: 'Windows VMs', // fixed, can't be renamed
        quantity: '168',
        growth: '0',
        vcpu: '4',
        memory: '8',
        dataEgress: '5000',
        storage: '3800',
    },
];

const internetEgressData: InternetEgress[] = [
    {
        expenseName: '0-99TB', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: '0.05', // can be modified
        notes: '', // can be modified
    },
    {
        expenseName: '100-249TB', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: '0.025', // can be modified
        notes: '', // can be modified
    },
    {
        expenseName: '1PB+', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: '0.009', // can be modified
        notes: '', // can be modified
    },
    {
        expenseName: '250-499TB', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: '0.015', // can be modified
        notes: '', // can be modified
    },
    {
        expenseName: '500-999TB', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        dataEgressCost: '0.01', // can be modified
        notes: '', // can be modified
    },
];

const miscellaneousCosts: MiscellaneousCosts[] = [
    {
        expenseName: 'Misc Expense 1', // fixed, can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '10', // can be modified
        notes: '', // can be modified
    },
    {
        expenseName: 'Misc Expense 2', // fixed, can't be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '200', // can be modified
        notes: '', // can be modified
    },
    {
        expenseName: 'Misc Expense 3', // fixed, can't be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: '500', // can be modified
        notes: '', // can be modified
    },
];

const metalExpensesData: Expense[] = [
    {
        expenseName: 'RHEL', // can be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '7', // can be modified
    },
    {
        expenseName: 'Windows', // can be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '15', // can be modified
    },
    {
        expenseName: 'Storage', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: '1', // can be modified
    },
    {
        expenseName: 'Cloud Software', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '1200', // can be modified
    },
    {
        expenseName: 'm3.large.x86 - Chicago Contract 3 year', // not fixed, but can't be renamed here. This name will come from the server list
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '1131.5', // can be modified
    },
];

const metalServersData: Server[] = [
    {
        serverName: 'm3.large.x86 - Chicago Contract 3 year', // can be modified
        vcpu: '32', // can be modified
        memory: '256', // can be modified
    },
];

const metalFtesData: FTE[] = [
    {
        fteName: 'Maintenance FTEs', // can be renamed
        annualExpense: '175000', // can be modified
        annualGrowth: '0', // can be modified
        quantity: '1.09', // can be modified
    },
];

const coloExpensesData: Expense[] = [
    {
        expenseName: 'Colo MRC', // can be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '1749', // can be modified
    },
    {
        expenseName: 'Colo Cabinet Install', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: '1500', // can be modified
    },
    {
        expenseName: 'Colo Circuit Install', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: '500', // can be modified
    },
    {
        expenseName: 'Dell R440 Acquisition', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: '3500', // can be modified
    },
    {
        expenseName: 'Dell R440 Vendor Support', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '200', // can be modified
    },
    {
        expenseName: 'Dell R440 Installation', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: '500', // can be modified
    },
    {
        expenseName: 'RHEL', // can be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '7', // can be modified
    },
    {
        expenseName: 'Windows', // can be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '15', // can be modified
    },
    {
        expenseName: 'Storage', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: '1', // can be modified
    },
    {
        expenseName: 'Cloud Software', // can be renamed
        recurring: RECURRING.YEARLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '700', // can be modified
    },
    {
        expenseName: 'ToR Switch 1', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: '21000', // can be modified
    },
    {
        expenseName: 'ToR Switch 2', // can be renamed
        recurring: RECURRING.NONE, // can be modified
        type: EXPENSE_TYPE.CAPEX, // can be modified
        value: '21000', // can be modified
    },
];

const coloServersData: Server[] = [
    {
        serverName: 'Dell R440', // fixed, can't be renamed
        vcpu: '72', // can be modified
        memory: '64', // can be modified
    },
    {
        serverName: 'Cabinet', // fixed, can't be renamed
        vcpu: '2880', // can be modified
        memory: '2560', // can be modified
    },
];

const coloFtesData: FTE[] = [
    {
        fteName: 'Maintenance FTEs', // can be renamed
        annualExpense: '175000', // can be modified
        annualGrowth: '0', // can be modified
        quantity: '0.83', // can be modified
    },
];

const cloudExpensesData: Expense[] = [
    {
        expenseName: 'Cloud Storage', // can't be renamed
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '0.1', // can be modified
    },
    {
        expenseName: 'D2s V5', // not fixed, but can't be renamed here. This name will come from the VMs list
        recurring: RECURRING.MONTHLY, // can be modified
        type: EXPENSE_TYPE.OPEX, // can be modified
        value: '27', // can be modified
    },
];

const cloudVMsData: Server[] = [
    {
        serverName: 'D2s V5', // can be modified
        vcpu: '2', // can be modified
        memory: '8', // can be modified
    },
];

const cloudFtesData: FTE[] = [
    {
        fteName: 'Maintenance FTEs', // can be renamed
        annualExpense: '175000', // can be modified
        annualGrowth: '0', // can be modified
        quantity: '1.35', // can be modified
    },
];

const cloudEgressExpense: CloudEgressExpense[] = [
    {
        expenseName: 'Tier 1', // fixed, can't be renamed
        size: '10100', // can be modified
        value: '0.087', // can be modified
    },
    {
        expenseName: 'Tier 2', // fixed, can't be renamed
        size: '50100', // can be modified
        value: '0.083', // can be modified
    },
    {
        expenseName: 'Tier 3', // fixed, can't be renamed
        size: '150100', // can be modified
        value: '0.07', // can be modified
    },
    {
        expenseName: 'Tier 4', // fixed, can't be renamed
        size: '500100', // can be modified
        value: '0.05', // can be modified
    },
];

// EXPORTS

export const initialData: VirtualMachineComparisonTemplate = {
    metal: {
        expenses: metalExpensesData,
        servers: metalServersData,
        ftes: metalFtesData,
        internetEgress: internetEgressData,
        miscellaneousCosts: miscellaneousCosts,
    },
    colo: {
        expenses: coloExpensesData,
        servers: coloServersData,
        ftes: coloFtesData,
        internetEgress: internetEgressData,
        miscellaneousCosts: miscellaneousCosts,
    },
    cloud: {
        expenses: cloudExpensesData,
        vms: cloudVMsData,
        ftes: cloudFtesData,
        egressExpense: cloudEgressExpense,
        miscellaneousCosts: miscellaneousCosts,
    },
};
