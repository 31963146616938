import { Skeleton } from '@/components/ui/skeleton.tsx';

import { TableBodyRow, TableHeadRow } from '../../shared/TableComponents';
import {
    NumberInputCell,
    TextInputCell,
} from '../../shared/TableComponents/CellComponents';
import { Server } from '../../shared/types.ts';
import { getMetalServers, useCloudRebalancingStore } from '../store';

const metalServerLabels: Partial<Record<keyof Server, { label: string }>> = {
    serverName: { label: 'Server' },
    vcpu: { label: 'vCPU' },
    memory: { label: 'Memory' },
};

const MetalServers = () => {
    const metalServers = useCloudRebalancingStore(getMetalServers);
    const updateMetalServer = useCloudRebalancingStore(
        (state) => state.updateMetalServer,
    );
    const loading = metalServers.length === 0;

    const getDuplicateIndexes = () => {
        const duplicates = new Set();
        metalServers.forEach((server, i) => {
            if (server.isDuplicate) {
                duplicates.add(i);
            }
        });
        return duplicates;
    };

    const duplicateIndexes = getDuplicateIndexes();

    return (
        <div className='w-full overflow-x-auto'>
            <table className='w-full table-fixed border-separate border-spacing-2 text-sm'>
                <thead>
                    {loading ? (
                        <SkeletonHeader />
                    ) : (
                        <TableHeadRow>
                            {Object.keys(metalServerLabels).map((key) => (
                                <th key={key} className='text-center'>
                                    <p className='whitespace-nowrap'>
                                        {
                                            metalServerLabels[
                                                key as keyof Server
                                            ].label
                                        }
                                    </p>
                                </th>
                            ))}
                        </TableHeadRow>
                    )}
                </thead>
                <tbody>
                    {loading ? (
                        <SkeletonRow />
                    ) : (
                        metalServers.map((server, index) => {
                            const isDuplicate = duplicateIndexes.has(index); // Definição de isDuplicate

                            return (
                                <TableBodyRow key={index}>
                                    <TextInputCell<Server>
                                        value={server.serverName}
                                        onChange={updateMetalServer}
                                        accessorKey={'serverName'}
                                        index={index}
                                        isEditable={true}
                                        className={`min-w-[200px] ${
                                            isDuplicate
                                                ? 'border-newDesign-error text-newDesign-error'
                                                : ''
                                        }`}
                                    />
                                    <NumberInputCell<Server>
                                        value={server.vcpu}
                                        index={index}
                                        accessorKey={'vcpu'}
                                        onChange={updateMetalServer}
                                    />
                                    <NumberInputCell<Server>
                                        value={server.memory}
                                        accessorKey={'memory'}
                                        index={index}
                                        onChange={updateMetalServer}
                                    />
                                </TableBodyRow>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
};

const SkeletonHeader = () => {
    return (
        <TableHeadRow>
            <th>Server</th>
            <th>
                <Skeleton />
            </th>
            <th>
                <Skeleton />
            </th>
        </TableHeadRow>
    );
};

const SkeletonRow = () => {
    const arr = Array.from({ length: 3 }, (_, i) => i);
    return arr.map((col) => (
        <TableBodyRow key={col}>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
            <td>
                <Skeleton />
            </td>
        </TableBodyRow>
    ));
};

export default MetalServers;
