import { Usecases } from '@bae/data-interface';

import CloudRebalancing from './Equinix/CloudRebalancing';
import DisasterRecovery from './Equinix/DisasterRecovery';
import VirtualMachineComparison from './Equinix/VirtualMachineComparison';

enum TemplateUseCase {
    CR = 'equinix/cr',
    VMC = 'equinix/vmc',
    DR = 'equinix/dr',
}

export const getTemplate = (useCase: Usecases) => {
    switch (useCase) {
        case TemplateUseCase.CR:
            return <CloudRebalancing />;
        case TemplateUseCase.VMC:
            return <VirtualMachineComparison />;
        case TemplateUseCase.DR:
            return <DisasterRecovery />;
        default:
            return null;
    }
};
