import { create } from 'zustand';

import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export enum DisasterRecoveryModes {
    PilotLight = 'Pilot Light',
    WarmStandby = 'Warm Standby',
    MultiSiteActive = 'Multi-site Active',
    BackupRestore = 'Backup & Restore',
}

interface DisasterRecoveryStore {
    data: {
        kind: DisasterRecoveryModes;
    };

    updateKind: (value: DisasterRecoveryModes) => void;
    resetData: (data?: { kind: DisasterRecoveryModes }) => void;
}

export const useDisasterRecoveryStore = create<DisasterRecoveryStore>()(
    devtools(
        immer((set) => ({
            data: {
                kind: DisasterRecoveryModes.PilotLight,
            },

            updateKind: (value: DisasterRecoveryStore['data']['kind']) =>
                set((state) => {
                    state.data.kind = value;
                }),

            // Reset
            resetData: (data?: { kind: DisasterRecoveryModes }) =>
                set({
                    data: data || { kind: DisasterRecoveryModes.PilotLight },
                }),
        })),
        {
            name: 'DisasterRecoveryStore',
        },
    ),
);
