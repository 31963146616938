import { ReactNode } from 'react';

import { cn } from '@/lib/utils.ts';

const TableHeadRow = ({ children }: { children: ReactNode }) => {
    return (
        <tr
            className={cn(
                'text-newDesign-text-secondary [&_th:first-child]:w-[200px] [&_th:first-child]:bg-newDesign-bgHeadTable md:[&_th:first-child]:w-[300px]',
                '[&_th:first-child]:text-center [&_th:first-child]:text-newDesign-primary [&_th:not(:last-child)]:mr-4',
                '[&_th]:h-[44px] [&_th]:w-[200px] [&_th]:rounded-md [&_th]:bg-newDesign-background [&_th]:px-2',
                '[&_th]:py-1 [&_th]:text-center [&_th_:first-child]:font-normal',
            )}>
            {children}
        </tr>
    );
};

export default TableHeadRow;
