import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import type { CloudRebalancingAPIInput } from '../types.ts';

const getCloudRebalanceModel = async ({
    groupId,
}: {
    groupId: string;
}): Promise<CloudRebalancingAPIInput> => {
    const response = await AxiosAPIv2.axiosInstance.get<{
        model_input: CloudRebalancingAPIInput;
    }>(`/partners/equinix/${groupId}`, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return response.data.model_input;
};

export const getCloudRebalanceQueryOptions = (groupId: string) => {
    return queryOptions({
        queryKey: ['partners', 'equinix', groupId],
        queryFn: () => getCloudRebalanceModel({ groupId }),
    });
};

type UseCloudRebalanceModelOptions = {
    queryConfig?: QueryConfig<typeof getCloudRebalanceModel>;
    refetchInterval?: number | false;
    groupId: string;
    enabled?: boolean;
};

export const useCloudRebalanceModel = ({
    queryConfig,
    refetchInterval,
    groupId,
    enabled = true,
}: UseCloudRebalanceModelOptions) => {
    return useQuery({
        ...getCloudRebalanceQueryOptions(groupId),
        ...queryConfig,
        refetchInterval,
        enabled,
    });
};
