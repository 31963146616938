import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import {
    metalAPIAdapter,
    coloAPIAdapter,
    cloudAPIAdapter,
    shareInputAPIAdapter,
} from '../adapters.ts';
import {
    VirtualMachineComparisonAPIInput,
    VirtualMachineComparisonResponse,
    VirtualMachineComparisonTemplate,
    SharedInput,
} from '../types.ts';

type UpdateVirtualMachineComparisonParams = {
    data: VirtualMachineComparisonTemplate & SharedInput;
    groupId: string;
};

const updateVirtualMachineComparison = async ({
    data,
    groupId,
}: UpdateVirtualMachineComparisonParams): Promise<VirtualMachineComparisonResponse> => {
    const url = `/partners/equinix`;

    const sharedInput = shareInputAPIAdapter(data);
    const metalData = metalAPIAdapter(data);
    const coloData = coloAPIAdapter(data);
    const cloudData = cloudAPIAdapter(data);

    const payload: VirtualMachineComparisonAPIInput = {
        ...sharedInput,
        ...metalData,
        ...coloData,
        ...cloudData,
        input_type: 'equinix_vmc',
    };

    const response =
        await AxiosAPIv2.axiosInstance.post<VirtualMachineComparisonResponse>(
            url,
            {
                model_input: payload,
                replace_group_id: groupId,
            },
            {
                headers: { Authorization: 'Bearer ' + getToken() },
            },
        );

    return response.data;
};

type UpdateVirtualMachineComparisonOptions = {
    mutationConfig?: MutationConfig<typeof updateVirtualMachineComparison>;
    invalidate?: boolean;
};

export const useUpdateVirtualMachineComparison = ({
    mutationConfig,
}: UpdateVirtualMachineComparisonOptions = {}) => {
    return useMutation({
        mutationFn: updateVirtualMachineComparison,
        ...mutationConfig,
    });
};
