import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import { DisasterRecoveryAPIInput } from '../types.ts';

const getDisasterRecoveryModel = async ({
    groupId,
}: {
    groupId: string;
}): Promise<DisasterRecoveryAPIInput> => {
    const response = await AxiosAPIv2.axiosInstance.get<{
        model_input: DisasterRecoveryAPIInput;
    }>(`/partners/equinix/${groupId}`, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return response.data.model_input;
};

export const getDisasterRecoveryQueryOptions = (groupId: string) => {
    return queryOptions({
        queryKey: ['partners', 'equinix', groupId],
        queryFn: () => getDisasterRecoveryModel({ groupId }),
    });
};

type UseDisasterRecoveryModelOptions = {
    queryConfig?: QueryConfig<typeof getDisasterRecoveryModel>;
    refetchInterval?: number | false;
    groupId: string;
    enabled?: boolean;
};

export const useDisasterRecoveryModel = ({
    queryConfig,
    refetchInterval,
    groupId,
    enabled = true,
}: UseDisasterRecoveryModelOptions) => {
    return useQuery({
        ...getDisasterRecoveryQueryOptions(groupId),
        ...queryConfig,
        refetchInterval,
        enabled,
    });
};
