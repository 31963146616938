import { ChangeEvent, ComponentProps, FocusEvent } from 'react';

import { removeLeadingZeros } from '@bae/utils';

import { Input } from '@/components/ui/input.tsx';
import { cn } from '@/lib/utils.ts';

const floatRegex = /^(\d+(\.\d*)?|\.\d*)?$/;

const FloatNumberInputCell = <T,>({
    value,
    onChange,
    accessorKey,
    index,
    className,
}: {
    value: string;
    onChange: <K extends keyof T>(index: number, key: K, value: T[K]) => void;
    accessorKey: keyof T;
    index: number;
    className?: ComponentProps<'div'>['className'];
}) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;

        if (floatRegex.test(inputValue)) {
            if (inputValue === '') {
                inputValue = '0';
            }

            onChange(
                index,
                accessorKey,
                removeLeadingZeros(inputValue) as T[keyof T],
            );
        }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        const parsedValue = parseFloat(inputValue);

        if (!Number.isNaN(parsedValue)) {
            const normalizedValue = parsedValue.toString();

            if (normalizedValue !== value) {
                onChange(index, accessorKey, normalizedValue as T[keyof T]);
            }
        } else {
            // In case something unexpected happens, fallback to "0"
            if (value !== '0') {
                onChange(index, accessorKey, '0' as T[keyof T]);
            }
        }
    };

    return (
        <td className='h-[36px] rounded-md border bg-white p-2 text-center'>
            <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                className={cn(
                    'h-6 w-full rounded-sm border-none text-center',
                    className,
                    '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none',
                    '[&::-webkit-inner-spin-button]:appearance-none',
                )}
                type='number'
            />
        </td>
    );
};

export default FloatNumberInputCell;
