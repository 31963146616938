import { format } from 'date-fns';
import { create } from 'zustand';

import { InputParameters, Instance } from './types.ts';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface TemplateCommonSettings {
    data: {
        inputParameters: InputParameters;
        instances: Instance[];
    };

    updateInputParameter: <K extends keyof InputParameters>(
        key: K,
        value: InputParameters[K],
    ) => void;

    // Instances
    updateInstance: <K extends keyof Instance>(
        index: number,
        key: K,
        value: Instance[K],
    ) => void;

    resetData: (data: Partial<TemplateCommonSettings['data']>) => void;
}

const inputParametersData: InputParameters = {
    dateStart: format(new Date(Date.now()), 'MM/dd/yyyy'),
    periods: '60',
    capitalCost: '11',
    depreciationTime: '36',
};

const useTemplateCommonStore = create<TemplateCommonSettings>()(
    devtools(
        immer((set) => ({
            data: {
                inputParameters: inputParametersData,
                instances: [],
            },

            updateInputParameter: (key, value) => {
                set((state) => {
                    state.data.inputParameters[key] = value;
                });
            },

            updateInstance: (index, key, value) => {
                set((state) => {
                    state.data.instances[index][key] = value;
                });
            },

            resetData: (data: Partial<TemplateCommonSettings['data']>) => {
                set((state) => {
                    state.data = {
                        inputParameters:
                            data.inputParameters || inputParametersData,
                        instances: data.instances,
                    };
                });
            },
        })),
        {
            name: 'TemplateCommonStore',
        },
    ),
);

const getInputParameters = (state: TemplateCommonSettings) => {
    return state.data.inputParameters;
};

const getInstances = (state: TemplateCommonSettings) => {
    return state.data.instances;
};

export { useTemplateCommonStore, getInputParameters, getInstances };
