import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { APIModelResponse, AxiosAPI, getToken } from '@bae/api';
import { Model, Usecases } from '@bae/data-interface';

interface GetModelParams {
    modelId: string;
}

export enum TemplateModelTypes {
    CR = 'equinix/cr',
    VMC = 'equinix/vmc',
    DR = 'equinix/dr',
}

export const getTemplateModelType = (
    modelType: Usecases,
): 'equinix/cr' | 'equinix/vmc' | 'equinix/dr' => {
    if (
        modelType === TemplateModelTypes.CR ||
        modelType === TemplateModelTypes.DR ||
        modelType === TemplateModelTypes.VMC
    ) {
        return modelType;
    }

    return undefined;
};

const adapter = (data: APIModelResponse): Model => {
    return {
        id: data.id,
        name: data.name,
        lastupdate: data.lastupdate,
        periods: data.periods,
        type: data.type,
        groupId: data.group_id,
    };
};

export const getModel = async ({ modelId }: GetModelParams): Promise<Model> => {
    const response = await AxiosAPI.axiosInstance.get<APIModelResponse>(
        `models/${modelId}`,
        { headers: { Authorization: 'Bearer ' + getToken() } },
    );

    return adapter(response.data);
};

export const getModelQueryOptions = ({ modelId }: GetModelParams) => {
    return queryOptions({
        queryKey: ['models', modelId],
        queryFn: () => getModel({ modelId }),
    });
};

type UseGetModelOptions = {
    modelId: string;
    queryConfig?: QueryConfig<typeof getModelQueryOptions>;
    enabled?: boolean;
};

export const useGetModel = ({
    queryConfig,
    modelId,
    enabled = true,
}: UseGetModelOptions) => {
    return useQuery({
        ...getModelQueryOptions({ modelId }),
        ...queryConfig,
        enabled,
    });
};
