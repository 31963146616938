export const TEMPLATES_MAP = {
    equinix: [
        {
            title: 'Cloud Rebalancing Settings',
            description:
                'The cloud rebalancing models compare the total cost of ownership (TCO) for a set of application server and database server instances. The cost of three scenarios are compared: Public Cloud, Equinix Metal, and Equinix colocation.',
            path: '/templates/cloud-rebalancing',
        },
        {
            title: 'Virtual Machine Comparison',
            description:
                'The virtual machines models compare the total cost of ownership (TCO) for a set of Linux and Windows VMs. The cost of three scenarios are compared: Public Cloud, Equinix Metal, and Equinix colocation.',
            path: '/templates/virtual-machine-comparison',
        },
        {
            title: 'Disaster Recovery Deployment Scenarios',
            description:
                'The cloud disaster recovery models compare the total cost of ownership (TCO) for four disaster recovery scenarios: Multisite Active, Warm Standby, Pilot Light, and Backup and Restore. The cost of four scenarios are compared: Public Cloud, Equinix Metal, Equinix Metal STaaS, and Equinix colocation.',
            path: '/templates/disaster-recovery',
        },
    ],
};
