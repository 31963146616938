import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceConnection } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import {
    getResourceZeroTimeseries,
    serviceResourceAdapter,
} from '../adapters.ts';
import { useEditServiceResourceDeployment } from './delayed-deployment/edit-resource-deployment.ts';

type EditServiceResourceParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: ServiceConnection;
    nextData: ServiceConnection;
};

const editServiceResource = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditServiceResourceParams): Promise<ServiceConnection> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/service_connections`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        svcname: prevData.serviceName,
        appname: prevData.applicationName,
        location: prevData.location,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = serviceResourceAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditServiceResourceOptions = {
    mutationConfig?: MutationConfig<typeof editServiceResource>;
};

export const useEditServiceResource = ({
    mutationConfig,
}: UseEditServiceResourceOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    const editServiceResourceDeployment = useEditServiceResourceDeployment({
        invalidate: false,
    });

    return useMutation({
        mutationFn: editServiceResource,
        onSuccess: async (data, variables, context) => {
            const { modelId, type, nextData, prevData } = variables;

            if (
                prevData.deployment.length === 1 &&
                prevData.deployment[0].delay === 0
            ) {
                const nextService = nextData.serviceName;
                const nextResource = nextData.applicationName;
                const nextLocation = nextData.location;

                await editServiceResourceDeployment.mutateAsync({
                    modelId,
                    type,
                    prevData: getResourceZeroTimeseries({
                        ...prevData,
                        serviceName: nextService,
                        applicationName: nextResource,
                        location: nextLocation,
                    }),
                    nextData: getResourceZeroTimeseries(nextData),
                });
            }

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
