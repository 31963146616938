import { QueryConfig } from '@/libs/api/src/lib/react-query.ts';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { APIModelGroup, AxiosAPIv2, getToken } from '@bae/api';
import { ModelGroup } from '@bae/data-interface';

const adapter = (data: { model_group: APIModelGroup }): ModelGroup => {
    return {
        id: data.model_group.group_id,
        name: data.model_group.group_name,
        type: data.model_group.group_type,
        models: data.model_group.models.map((d) => ({
            id: d.model_id,
            name: d.model_name,
            description: d.model_description,
            createdAt: d.created_at,
            createdBy: d.created_by,
            lastUpdateAt: d.lastupdate_at,
            lastUpdateBy: d.lastupdate_by,
        })),
        periods: data.model_group.periods,
    };
};

interface GetModelGroupParams {
    groupId: string;
}

export const getModelGroup = async ({
    groupId,
}: GetModelGroupParams): Promise<ModelGroup> => {
    const response = await AxiosAPIv2.axiosInstance.get<{
        model_group: APIModelGroup;
    }>(`model_groups/${groupId}`, {
        headers: { Authorization: 'Bearer ' + getToken() },
    });

    return adapter(response.data);
};

export const getModelGroupQueryOptions = ({ groupId }: GetModelGroupParams) => {
    return queryOptions({
        queryKey: ['model_groups', groupId],
        queryFn: () => getModelGroup({ groupId }),
    });
};

type UseListModelGroupOptions = {
    groupId: string;
    queryConfig?: QueryConfig<typeof getModelGroupQueryOptions>;
    enabled?: boolean;
};

export const useGetModelGroup = ({
    groupId,
    queryConfig,
    enabled = true,
}: UseListModelGroupOptions) => {
    return useQuery({
        ...getModelGroupQueryOptions({ groupId }),
        ...queryConfig,
        enabled,
    });
};
