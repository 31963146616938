import { format } from 'date-fns';

export const toTemplateDateFormat = (date: string) => {
    const isoDate = new Date(date);

    return `${isoDate.getMonth() + 1}/${isoDate.getDate() + 1}/${isoDate.getFullYear()}`;
};

export const toBaeDateFormat = (date: string) => {
    return format(date, 'yyyy-MM-dd');
};
