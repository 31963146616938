import { AxiosRequestConfig } from 'axios';

import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';
import { ServiceDemand } from '@bae/data-interface';

import { getQueryKey } from '@/features/input/api/get-input.ts';

import { getDemandZeroTimeseries, serviceDemandAdapter } from '../adapters.ts';
import { useEditServiceDemandDeployment } from './delayed-deployment/edit-demand-deployment.ts';

type EditServiceDemandParams = {
    modelId: string;
    type: 'model' | 'library';
    prevData: ServiceDemand;
    nextData: ServiceDemand;
};

const editServiceDemand = async ({
    modelId,
    type,
    prevData,
    nextData,
}: EditServiceDemandParams): Promise<ServiceDemand> => {
    const endpointType = type === 'model' ? 'models' : 'libraries';
    const url = `/${endpointType}/${modelId}/inputs/service_demand`;

    const headers = { Authorization: `Bearer ${getToken()}` };

    const params = {
        tenantname: prevData.tenantName,
        endpointname: prevData.endpointName,
        svcname: prevData.serviceName,
        model_id: modelId,
    };

    const config: AxiosRequestConfig = {
        headers,
        params,
    };

    const payload = serviceDemandAdapter(nextData);

    await AxiosAPIv2.axiosInstance.patch(url, payload, config);

    return nextData;
};

type UseEditServiceDemandOptions = {
    mutationConfig?: MutationConfig<typeof editServiceDemand>;
};

export const useEditServiceDemand = ({
    mutationConfig,
}: UseEditServiceDemandOptions = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, ...restConfig } = mutationConfig || {};

    const editServiceDemandDeploymentQuery = useEditServiceDemandDeployment({
        invalidate: false,
    });

    return useMutation({
        mutationFn: editServiceDemand,
        onSuccess: async (data, variables, context) => {
            const { modelId, type, nextData, prevData } = variables;

            if (
                prevData.deployment.length === 1 &&
                prevData.deployment[0].delay === 0
            ) {
                const nextEndpoint = nextData.endpointName;
                const nextTenant = nextData.tenantName;

                await editServiceDemandDeploymentQuery.mutateAsync({
                    modelId,
                    type,
                    prevData: getDemandZeroTimeseries({
                        ...prevData,
                        endpointName: nextEndpoint,
                        tenantName: nextTenant,
                    }),
                    nextData: getDemandZeroTimeseries(nextData),
                });
            }

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(modelId, type),
            });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        ...restConfig,
    });
};
