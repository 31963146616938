import React from 'react';

import { useCurrency } from '@bae/store';
import { Loading } from '@bae/ui';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils.ts';

import { FinancialStatementData } from './types';
import { formatStatementCurrency, getRowTotal } from './utils';

const getValueColorLocal = (value: number, positive: boolean): string => {
    return positive
        ? value >= 0
            ? 'text-newDesign-primary'
            : 'text-newDesign-indicatorsLine'
        : value >= 0
          ? 'text-newDesign-indicatorsLine'
          : 'text-newDesign-primary';
};

// pl-2 pl-4 pl-6 pl-8 pl-10 pl-12 pl-14 pl-16 pl-18 pl-20 pl-22 pl-24 pl-26 pl-28 pl-30

interface TableFinancialStatementProps {
    data: FinancialStatementData;
    loading: boolean;
}

const TableFinancialStatement = ({
    data,
    loading,
}: TableFinancialStatementProps) => {
    const { currency } = useCurrency();

    const columns = [
        {
            key: 'name',
            header: 'Periods',
            renderCell: (row: any) => (
                <div
                    className={cn(
                        'text-left font-normal',
                        { 'font-bold': row.bold },
                        `pl-${2 * row.indent + 2}`,
                    )}>
                    {row.name}
                </div>
            ),
        },
        ...data.header.map((header, index) => ({
            key: header,
            header: header,
            renderCell: (row: any) => {
                const value = row.data[index];
                return (
                    <div
                        className={`${getValueColorLocal(value, row.positive)} text-right`}>
                        {formatStatementCurrency(value, row.positive, currency)}
                    </div>
                );
            },
        })),
        {
            key: 'total',
            header: 'Total',
            renderCell: (row: any) => {
                const total = getRowTotal(row.data);
                return (
                    <div
                        className={`font-semibold ${getValueColorLocal(total, row.positive)} text-right`}>
                        {formatStatementCurrency(total, row.positive, currency)}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            {loading ? (
                <Loading height={400} size={80} />
            ) : (
                <Table className='min-w-full'>
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHead
                                    key={column.key}
                                    className={`text-nowrap bg-newDesign-background text-sm font-medium text-newDesign-text-primary ${
                                        column.header === 'Periods'
                                            ? 'text-left'
                                            : 'text-right'
                                    }`}>
                                    {column.header}
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {data.body.map((row, rowIndex) => (
                            <TableRow
                                key={rowIndex}
                                className={`border-b ${
                                    row.highlight
                                        ? 'border-newDesign-border border-t'
                                        : 'border-white'
                                }`}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.key}
                                        className={cn(
                                            'text-nowrap bg-white py-1 pr-4 text-sm text-newDesign-text-primary',
                                            {
                                                [`pl-${row.indent * 2 + 2}`]:
                                                    column.key === 'name',
                                            },
                                        )}>
                                        {column.renderCell(row)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </>
    );
};

export default TableFinancialStatement;
