import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import type { InputParameters } from '../../shared/types.ts';
import { shareInputAPIAdapter } from '../adapters.ts';
import { DisasterRecoveryModes } from '../store';
import type {
    DisasterRecoveryAPIInput,
    DisasterRecoveryResponse,
} from '../types.ts';

type UpdateDisasterRecoveryParams = {
    data: { kind: DisasterRecoveryModes } & {
        inputParameters: InputParameters;
    };
    groupId: string;
};

const updateDisasterRecovery = async ({
    data,
    groupId,
}: UpdateDisasterRecoveryParams): Promise<DisasterRecoveryResponse> => {
    const url = `/partners/equinix`;

    const sharedInput = shareInputAPIAdapter(data);

    const payload: DisasterRecoveryAPIInput = {
        ...sharedInput,
        kind: data.kind,
        input_type: 'equinix_disaster_recovery',
    };

    const response =
        await AxiosAPIv2.axiosInstance.post<DisasterRecoveryResponse>(
            url,
            {
                model_input: payload,
                replace_group_id: groupId,
            },
            {
                headers: { Authorization: 'Bearer ' + getToken() },
            },
        );

    return response.data;
};

type UpdateDisasterRecoveryOptions = {
    mutationConfig?: MutationConfig<typeof updateDisasterRecovery>;
    invalidate?: boolean;
};

export const useUpdateDisasterRecovery = ({
    mutationConfig,
}: UpdateDisasterRecoveryOptions = {}) => {
    return useMutation({
        mutationFn: updateDisasterRecovery,
        ...mutationConfig,
    });
};
