import { ComponentProps, FocusEvent } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { useCurrency } from '@bae/store';

import { cn } from '@/lib/utils.ts';

const CurrencyInputCell = <T,>({
    value,
    onChange,
    accessorKey,
    index,
    className,
}: {
    value: string;
    onChange: <K extends keyof T>(index: number, key: K, value: T[K]) => void;
    accessorKey: keyof T;
    index: number;
    className?: ComponentProps<'div'>['className'];
}) => {
    const { currency } = useCurrency();

    const handleInputChange = (value: string) => {
        if (value === '') {
            onChange(index, accessorKey, 0 as T[keyof T]);
            return;
        }

        onChange(
            index,
            accessorKey,
            value.replace(/[^\d.]/g, '') as T[keyof T],
        );
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;

        // Remove any non-numeric and non-decimal characters (except for the decimal point).
        // For example, this regex removes everything except digit, '.'.
        inputValue = inputValue.replace(/[^\d.]/g, '');

        const parsedValue = parseFloat(inputValue);

        if (!Number.isNaN(parsedValue)) {
            const normalizedValue = parsedValue.toString();

            if (normalizedValue !== value) {
                onChange(index, accessorKey, normalizedValue as T[keyof T]);
            }
        } else {
            // In case something unexpected happens, fallback to "0"
            if (value !== '0') {
                onChange(index, accessorKey, '0' as T[keyof T]);
            }
        }
    };

    return (
        <td className='h-[36px] rounded-md border bg-white p-2 text-center'>
            <CurrencyInput
                value={value}
                onBlur={handleBlur}
                intlConfig={{ locale: 'en-US', currency }}
                className={cn(
                    'h-6 w-full rounded-sm border-none text-center focus-visible:outline-none',
                    'focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2',
                    className,
                )}
                decimalsLimit={8}
                defaultValue={0}
                onValueChange={(value) => {
                    handleInputChange(value || '0');
                }}
            />
        </td>
    );
};

export default CurrencyInputCell;
