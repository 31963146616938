export enum RECURRING {
    NONE = 'None',
    MONTHLY = 'Monthly',
    YEARLY = 'Yearly',
}

export enum EXPENSE_TYPE {
    CAPEX = 'CapEx',
    OPEX = 'OpEx',
}

export interface Expense {
    expenseName: string;
    recurring: RECURRING;
    type: EXPENSE_TYPE;
    value: string;
    isDuplicate?: boolean;
}

export interface EgressExpense {
    expenseName: string;
    size: string;
    value: string;
}

export interface Server {
    serverName: string;
    vcpu: string;
    memory: string;
    isDuplicate?: boolean;
}

export interface FTE {
    fteName: string;
    annualExpense: string;
    annualGrowth: string;
    quantity: string;
}

export interface InternetEgress {
    expenseName: string;
    recurring: RECURRING;
    type: EXPENSE_TYPE;
    dataEgressCost: string;
    notes: string | null;
}

export interface MiscellaneousCosts {
    expenseName: string;
    recurring: RECURRING;
    type: EXPENSE_TYPE;
    value: string;
    notes: string | null;
}

export interface InputParameters {
    dateStart: string; // YYYY-MM-DD
    periods: string; // months
    capitalCost: string; // float between 0 and 1
    depreciationTime: string; // months
}

export interface Instance {
    name?: string;
    quantity: string;
    growth: string;
    vcpu: string;
    memory: string;
    dataEgress: string;
    storage: string;
}
