import { toBaeDateFormat } from '../shared/InputParameters/utils.ts';
import type { InputParameters } from '../shared/types.ts';
import { DisasterRecoveryModes } from './store';
import type { DisasterRecoveryAPIInput, SharedInputAPI } from './types.ts';

export const toSharedInputData = (
    input: DisasterRecoveryAPIInput,
): { inputParameters: InputParameters } => {
    return {
        inputParameters: {
            dateStart: input.model_start_date,
            periods: input.model_run_time.toString(),
            capitalCost: input.cost_of_capital.toString(),
            depreciationTime: input.depreciation_time.toString(),
        },
    };
};

export const shareInputAPIAdapter = (
    _data: { kind: DisasterRecoveryModes } & {
        inputParameters: InputParameters;
    },
): SharedInputAPI => {
    return {
        model_start_date: toBaeDateFormat(_data.inputParameters.dateStart),
        model_run_time: parseFloat(_data.inputParameters.periods),
        cost_of_capital: parseFloat(_data.inputParameters.capitalCost),
        depreciation_time: parseFloat(_data.inputParameters.depreciationTime),
    };
};
