import { MutationConfig } from '@/libs/api/src/lib/react-query.ts';
import { useMutation } from '@tanstack/react-query';

import { AxiosAPIv2, getToken } from '@bae/api';

import type { InputParameters } from '../../shared/types.ts';
import { shareInputAPIAdapter } from '../adapters.ts';
import { DisasterRecoveryModes } from '../store';
import type {
    DisasterRecoveryAPIInput,
    DisasterRecoveryResponse,
} from '../types.ts';

type CreateDisasterRecoveryParams = {
    data: { kind: DisasterRecoveryModes } & {
        inputParameters: InputParameters;
    };
    name: string;
};

const createDisasterRecovery = async ({
    data,
    name,
}: CreateDisasterRecoveryParams): Promise<DisasterRecoveryResponse> => {
    const url = `/partners/equinix`;

    const sharedInput = shareInputAPIAdapter(data);

    const payload: DisasterRecoveryAPIInput = {
        ...sharedInput,
        kind: data.kind,
        input_type: 'equinix_disaster_recovery',
    };

    const response =
        await AxiosAPIv2.axiosInstance.post<DisasterRecoveryResponse>(
            url,
            {
                model_input: payload,
                group_name: name,
            },
            {
                headers: { Authorization: 'Bearer ' + getToken() },
            },
        );

    return response.data;
};

type CreateDisasterRecoveryOptions = {
    mutationConfig?: MutationConfig<typeof createDisasterRecovery>;
    invalidate?: boolean;
};

export const useCreateDisasterRecovery = ({
    mutationConfig,
}: CreateDisasterRecoveryOptions = {}) => {
    return useMutation({
        mutationFn: createDisasterRecovery,
        ...mutationConfig,
    });
};
