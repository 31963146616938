import { toBaeDateFormat } from '../shared/InputParameters/utils.ts';
import type {
    Expense,
    FTE,
    InternetEgress,
    MiscellaneousCosts,
    Server,
} from '../shared/types.ts';
import type {
    CloudRebalancingAPIInput,
    CloudRebalancingCloudAPI,
    CloudRebalancingColoAPI,
    CloudRebalancingMetalAPI,
    CloudRebalancingTemplate,
    SharedInput,
    SharedInputAPI,
} from './types.ts';

export const toSharedInputData = (
    input: CloudRebalancingAPIInput,
): SharedInput => {
    return {
        inputParameters: {
            dateStart: input.model_start_date,
            periods: input.model_run_time.toString(),
            capitalCost: input.cost_of_capital.toString(),
            depreciationTime: input.depreciation_time.toString(),
        },
        instances: [
            {
                name: 'App Server Instances',
                quantity: input.instances_1_initial_quantity.toString(),
                growth: input.instances_1_growth_rate.toString(),
                vcpu: input.instances_1_vcpu.toString(),
                memory: input.instances_1_memory_gb.toString(),
                dataEgress: input.instances_1_egress.toString(),
                storage: input.instances_1_storage_gb.toString(),
            },
            {
                name: 'Database Instances',
                quantity: input.instances_2_initial_quantity.toString(),
                growth: input.instances_2_growth_rate.toString(),
                vcpu: input.instances_2_vcpu.toString(),
                memory: input.instances_2_memory_gb.toString(),
                dataEgress: input.instances_2_egress.toString(),
                storage: input.instances_2_storage_gb.toString(),
            },
        ],
    };
};

export function toMetalData(
    input: CloudRebalancingAPIInput,
): CloudRebalancingTemplate['metal'] {
    const expenses: Expense[] = [
        {
            expenseName: input.metal_expense_1_name,
            recurring: input.metal_expense_1_recurring,
            type: input.metal_expense_1_type,
            value: input.metal_expense_1_value.toString(),
        },
        {
            expenseName: input.metal_server_1_name,
            recurring: input.metal_expense_2_recurring,
            type: input.metal_expense_2_type,
            value: input.metal_expense_2_value.toString(),
        },

        {
            expenseName: input.metal_server_2_name,
            recurring: input.metal_expense_3_recurring,
            type: input.metal_expense_3_type,
            value: input.metal_expense_3_value.toString(),
        },
        {
            expenseName: input.metal_expense_4_name,
            recurring: input.metal_expense_4_recurring,
            type: input.metal_expense_4_type,
            value: input.metal_expense_4_value.toString(),
        },
        {
            expenseName: input.metal_expense_5_name,
            recurring: input.metal_expense_5_recurring,
            type: input.metal_expense_5_type,
            value: input.metal_expense_5_value.toString(),
        },
    ];

    const servers: Server[] = [
        {
            serverName: input.metal_server_1_name,
            vcpu: input.metal_server_1_vcpu.toString(),
            memory: input.metal_server_1_memory_gb.toString(),
        },
        {
            serverName: input.metal_server_2_name,
            vcpu: input.metal_server_2_vcpu.toString(),
            memory: input.metal_server_2_memory_gb.toString(),
        },
    ];

    const internetEgressNames = [
        '0-99TB',
        '100-249TB',
        '1PB+',
        '250-499TB',
        '500-999TB',
    ];
    const internetEgress: InternetEgress[] = [
        {
            expenseName: internetEgressNames[0],
            recurring: input.metal_egress_1_recurring,
            type: input.metal_egress_1_type,
            dataEgressCost: input.metal_egress_1_value.toString(),
            notes: input.metal_egress_1_notes,
        },
        {
            expenseName: internetEgressNames[1],
            recurring: input.metal_egress_2_recurring,
            type: input.metal_egress_2_type,
            dataEgressCost: input.metal_egress_2_value.toString(),
            notes: input.metal_egress_2_notes,
        },
        {
            expenseName: internetEgressNames[2],
            recurring: input.metal_egress_3_recurring,
            type: input.metal_egress_3_type,
            dataEgressCost: input.metal_egress_3_value.toString(),
            notes: input.metal_egress_3_notes,
        },
        {
            expenseName: internetEgressNames[3],
            recurring: input.metal_egress_4_recurring,
            type: input.metal_egress_4_type,
            dataEgressCost: input.metal_egress_4_value.toString(),
            notes: input.metal_egress_4_notes,
        },
        {
            expenseName: internetEgressNames[4],
            recurring: input.metal_egress_5_recurring,
            type: input.metal_egress_5_type,
            dataEgressCost: input.metal_egress_5_value.toString(),
            notes: input.metal_egress_5_notes,
        },
    ];

    const miscellaneousNames = [
        'Misc Expense 1',
        'Misc Expense 2',
        'Misc Expense 3',
    ];
    const miscellaneousCosts: MiscellaneousCosts[] = [
        {
            expenseName: miscellaneousNames[0],
            recurring: input.metal_misc_expense_1_recurring,
            type: input.metal_misc_expense_1_type,
            value: input.metal_misc_expense_1_value.toString(),
            notes: input.metal_misc_expense_1_notes,
        },
        {
            expenseName: miscellaneousNames[1],
            recurring: input.metal_misc_expense_2_recurring,
            type: input.metal_misc_expense_2_type,
            value: input.metal_misc_expense_2_value.toString(),
            notes: input.metal_misc_expense_2_notes,
        },
        {
            expenseName: miscellaneousNames[2],
            recurring: input.metal_misc_expense_3_recurring,
            type: input.metal_misc_expense_3_type,
            value: input.metal_misc_expense_3_value.toString(),
            notes: input.metal_misc_expense_3_notes,
        },
    ];

    const ftes: FTE[] = [
        {
            fteName: input.metal_ftes_name,
            annualExpense: input.metal_ftes_value.toString(),
            annualGrowth: input.metal_ftes_growth.toString(),
            quantity: input.metal_ftes_number.toString(),
        },
    ];

    return { expenses, servers, ftes, internetEgress, miscellaneousCosts };
}

export function toColoData(
    input: CloudRebalancingAPIInput,
): CloudRebalancingTemplate['colo'] {
    const expenses: Expense[] = [
        {
            expenseName: input.colo_expense_1_name,
            recurring: input.colo_expense_1_recurring,
            type: input.colo_expense_1_type,
            value: input.colo_expense_1_value.toString(),
        },
        {
            expenseName: input.colo_expense_2_name,
            recurring: input.colo_expense_2_recurring,
            type: input.colo_expense_2_type,
            value: input.colo_expense_2_value.toString(),
        },
        {
            expenseName: input.colo_expense_3_name,
            recurring: input.colo_expense_3_recurring,
            type: input.colo_expense_3_type,
            value: input.colo_expense_3_value.toString(),
        },
        {
            expenseName: input.colo_expense_4_name,
            recurring: input.colo_expense_4_recurring,
            type: input.colo_expense_4_type,
            value: input.colo_expense_4_value.toString(),
        },
        {
            expenseName: input.colo_expense_5_name,
            recurring: input.colo_expense_5_recurring,
            type: input.colo_expense_5_type,
            value: input.colo_expense_5_value.toString(),
        },
        {
            expenseName: input.colo_expense_6_name,
            recurring: input.colo_expense_6_recurring,
            type: input.colo_expense_6_type,
            value: input.colo_expense_6_value.toString(),
        },
        {
            expenseName: input.colo_expense_7_name,
            recurring: input.colo_expense_7_recurring,
            type: input.colo_expense_7_type,
            value: input.colo_expense_7_value.toString(),
        },
        {
            expenseName: input.colo_expense_8_name,
            recurring: input.colo_expense_8_recurring,
            type: input.colo_expense_8_type,
            value: input.colo_expense_8_value.toString(),
        },
        {
            expenseName: input.colo_expense_9_name,
            recurring: input.colo_expense_9_recurring,
            type: input.colo_expense_9_type,
            value: input.colo_expense_9_value.toString(),
        },
        {
            expenseName: input.colo_expense_10_name,
            recurring: input.colo_expense_10_recurring,
            type: input.colo_expense_10_type,
            value: input.colo_expense_10_value.toString(),
        },
        {
            expenseName: input.colo_expense_11_name,
            recurring: input.colo_expense_11_recurring,
            type: input.colo_expense_11_type,
            value: input.colo_expense_11_value.toString(),
        },
        {
            expenseName: input.colo_expense_12_name,
            recurring: input.colo_expense_12_recurring,
            type: input.colo_expense_12_type,
            value: input.colo_expense_12_value.toString(),
        },
        {
            expenseName: input.colo_expense_13_name,
            recurring: input.colo_expense_13_recurring,
            type: input.colo_expense_13_type,
            value: input.colo_expense_13_value.toString(),
        },
        {
            expenseName: input.colo_expense_14_name,
            recurring: input.colo_expense_14_recurring,
            type: input.colo_expense_14_type,
            value: input.colo_expense_14_value.toString(),
        },
    ];

    const servers: Server[] = [
        {
            serverName: 'App Server', // fixed as per initial data
            vcpu: input.colo_server_1_vcpu.toString(),
            memory: input.colo_server_1_memory_gb.toString(),
        },
        {
            serverName: 'DB Server', // fixed as per initial data
            vcpu: input.colo_server_2_vcpu.toString(),
            memory: input.colo_server_2_memory_gb.toString(),
        },
        {
            serverName: 'Colo Cabinet', // fixed as per initial data
            vcpu: input.colo_server_3_vcpu.toString(),
            memory: null, // fixed as per initial data
        },
    ];

    const internetEgressNames = [
        '0-99TB',
        '100-249TB',
        '1PB+',
        '250-499TB',
        '500-999TB',
    ];
    const internetEgress: InternetEgress[] = [
        {
            expenseName: internetEgressNames[0],
            recurring: input.colo_egress_1_recurring,
            type: input.colo_egress_1_type,
            dataEgressCost: input.colo_egress_1_value.toString(),
            notes: input.colo_egress_1_notes,
        },
        {
            expenseName: internetEgressNames[1],
            recurring: input.colo_egress_2_recurring,
            type: input.colo_egress_2_type,
            dataEgressCost: input.colo_egress_2_value.toString(),
            notes: input.colo_egress_2_notes,
        },
        {
            expenseName: internetEgressNames[2],
            recurring: input.colo_egress_3_recurring,
            type: input.colo_egress_3_type,
            dataEgressCost: input.colo_egress_3_value.toString(),
            notes: input.colo_egress_3_notes,
        },
        {
            expenseName: internetEgressNames[3],
            recurring: input.colo_egress_4_recurring,
            type: input.colo_egress_4_type,
            dataEgressCost: input.colo_egress_4_value.toString(),
            notes: input.colo_egress_4_notes,
        },
        {
            expenseName: internetEgressNames[4],
            recurring: input.colo_egress_5_recurring,
            type: input.colo_egress_5_type,
            dataEgressCost: input.colo_egress_5_value.toString(),
            notes: input.colo_egress_5_notes,
        },
    ];

    const miscellaneousNames = [
        'Misc Expense 1',
        'Misc Expense 2',
        'Misc Expense 3',
    ];
    const miscellaneousCosts: MiscellaneousCosts[] = [
        {
            expenseName: miscellaneousNames[0],
            recurring: input.colo_misc_expense_1_recurring,
            type: input.colo_misc_expense_1_type,
            value: input.colo_misc_expense_1_value.toString(),
            notes: input.colo_misc_expense_1_notes,
        },
        {
            expenseName: miscellaneousNames[1],
            recurring: input.colo_misc_expense_2_recurring,
            type: input.colo_misc_expense_2_type,
            value: input.colo_misc_expense_2_value.toString(),
            notes: input.colo_misc_expense_2_notes,
        },
        {
            expenseName: miscellaneousNames[2],
            recurring: input.colo_misc_expense_3_recurring,
            type: input.colo_misc_expense_3_type,
            value: input.colo_misc_expense_3_value.toString(),
            notes: input.colo_misc_expense_3_notes,
        },
    ];

    const ftes: FTE[] = [
        {
            fteName: input.colo_ftes_name,
            annualExpense: input.colo_ftes_value.toString(),
            annualGrowth: input.colo_ftes_growth.toString(),
            quantity: input.colo_ftes_number.toString(),
        },
    ];

    return { expenses, servers, ftes, internetEgress, miscellaneousCosts };
}

export function toCloudData(
    input: CloudRebalancingAPIInput,
): CloudRebalancingTemplate['cloud'] {
    const expenses: Expense[] = [
        {
            expenseName: input.cloud_expense_1_name,
            recurring: input.cloud_expense_1_recurring,
            type: input.cloud_expense_1_type,
            value: input.cloud_expense_1_value.toString(),
        },
        {
            // For cloud_expense_2_name = vm_1_name
            expenseName: input.cloud_vm_1_name,
            recurring: input.cloud_expense_2_recurring,
            type: input.cloud_expense_2_type,
            value: input.cloud_expense_2_value.toString(),
        },
        {
            expenseName: input.cloud_expense_3_name,
            recurring: input.cloud_expense_3_recurring,
            type: input.cloud_expense_3_type,
            value: input.cloud_expense_3_value.toString(),
        },
        {
            expenseName: input.cloud_expense_4_name,
            recurring: input.cloud_expense_4_recurring,
            type: input.cloud_expense_4_type,
            value: input.cloud_expense_4_value.toString(),
        },
    ];

    const vms: Server[] = [
        {
            serverName: input.cloud_vm_1_name,
            vcpu: input.cloud_vm_1_vcpu.toString(),
            memory: input.cloud_vm_1_memory_gb.toString(),
        },
    ];

    const miscellaneousNames = [
        'Misc Expense 1',
        'Misc Expense 2',
        'Misc Expense 3',
    ];
    const miscellaneousCosts: MiscellaneousCosts[] = [
        {
            expenseName: miscellaneousNames[0],
            recurring: input.cloud_misc_expense_1_recurring,
            type: input.cloud_misc_expense_1_type,
            value: input.cloud_misc_expense_1_value.toString(),
            notes: input.cloud_misc_expense_1_notes,
        },
        {
            expenseName: miscellaneousNames[1],
            recurring: input.cloud_misc_expense_2_recurring,
            type: input.cloud_misc_expense_2_type,
            value: input.cloud_misc_expense_2_value.toString(),
            notes: input.cloud_misc_expense_2_notes,
        },
        {
            expenseName: miscellaneousNames[2],
            recurring: input.cloud_misc_expense_3_recurring,
            type: input.cloud_misc_expense_3_type,
            value: input.cloud_misc_expense_3_value.toString(),
            notes: input.cloud_misc_expense_3_notes,
        },
    ];

    const ftes: FTE[] = [
        {
            fteName: input.cloud_ftes_name,
            annualExpense: input.cloud_ftes_value.toString(),
            annualGrowth: input.cloud_ftes_growth.toString(),
            quantity: input.cloud_ftes_number.toString(),
        },
    ];

    return { expenses, vms, ftes, miscellaneousCosts };
}

export const shareInputAPIAdapter = (
    _data: CloudRebalancingTemplate & SharedInput,
): SharedInputAPI => {
    return {
        model_start_date: toBaeDateFormat(_data.inputParameters.dateStart),
        model_run_time: parseFloat(_data.inputParameters.periods),
        cost_of_capital: parseFloat(_data.inputParameters.capitalCost),
        depreciation_time: parseFloat(_data.inputParameters.depreciationTime),

        instances_1_initial_quantity: parseFloat(_data.instances[0].quantity),
        instances_1_growth_rate: parseFloat(_data.instances[0].growth),
        instances_1_vcpu: parseFloat(_data.instances[0].vcpu),
        instances_1_memory_gb: parseFloat(_data.instances[0].memory),
        instances_1_egress: parseFloat(_data.instances[0].dataEgress),
        instances_1_storage_gb: parseFloat(_data.instances[0].storage),

        instances_2_initial_quantity: parseFloat(_data.instances[1].quantity),
        instances_2_growth_rate: parseFloat(_data.instances[1].growth),
        instances_2_vcpu: parseFloat(_data.instances[1].vcpu),
        instances_2_memory_gb: parseFloat(_data.instances[1].memory),
        instances_2_egress: parseFloat(_data.instances[1].dataEgress),
        instances_2_storage_gb: parseFloat(_data.instances[1].storage),
    };
};

export const metalAPIAdapter = (
    _data: CloudRebalancingTemplate & SharedInput,
): CloudRebalancingMetalAPI => {
    return {
        // Expenses
        metal_expense_1_name: _data.metal.expenses[0].expenseName,
        metal_expense_1_recurring: _data.metal.expenses[0].recurring,
        metal_expense_1_type: _data.metal.expenses[0].type,
        metal_expense_1_value: parseFloat(_data.metal.expenses[0].value),

        metal_expense_2_recurring: _data.metal.expenses[1].recurring,
        metal_expense_2_type: _data.metal.expenses[1].type,
        metal_expense_2_value: parseFloat(_data.metal.expenses[1].value),

        metal_expense_3_recurring: _data.metal.expenses[2].recurring,
        metal_expense_3_type: _data.metal.expenses[2].type,
        metal_expense_3_value: parseFloat(_data.metal.expenses[2].value),

        metal_expense_4_name: _data.metal.expenses[3].expenseName,
        metal_expense_4_recurring: _data.metal.expenses[3].recurring,
        metal_expense_4_type: _data.metal.expenses[3].type,
        metal_expense_4_value: parseFloat(_data.metal.expenses[3].value),

        metal_expense_5_name: _data.metal.expenses[4].expenseName,
        metal_expense_5_recurring: _data.metal.expenses[4].recurring,
        metal_expense_5_type: _data.metal.expenses[4].type,
        metal_expense_5_value: parseFloat(_data.metal.expenses[4].value),

        // Servers

        metal_server_1_name: _data.metal.servers[0].serverName,
        metal_server_1_vcpu: parseFloat(_data.metal.servers[0].vcpu),
        metal_server_1_memory_gb: parseFloat(_data.metal.servers[0].memory),

        metal_server_2_name: _data.metal.servers[1].serverName,
        metal_server_2_vcpu: parseFloat(_data.metal.servers[1].vcpu),
        metal_server_2_memory_gb: parseFloat(_data.metal.servers[1].memory),

        // FTEs
        metal_ftes_name: _data.metal.ftes[0].fteName,
        metal_ftes_value: parseFloat(_data.metal.ftes[0].annualExpense),
        metal_ftes_growth: parseFloat(_data.metal.ftes[0].annualGrowth),
        metal_ftes_number: parseFloat(_data.metal.ftes[0].quantity),

        // Internet Egress
        metal_egress_1_recurring: _data.metal.internetEgress[0].recurring,
        metal_egress_1_type: _data.metal.internetEgress[0].type,
        metal_egress_1_value: parseFloat(
            _data.metal.internetEgress[0].dataEgressCost,
        ),
        metal_egress_1_notes: _data.metal.internetEgress[0].notes,

        metal_egress_2_recurring: _data.metal.internetEgress[1].recurring,
        metal_egress_2_type: _data.metal.internetEgress[1].type,
        metal_egress_2_value: parseFloat(
            _data.metal.internetEgress[1].dataEgressCost,
        ),
        metal_egress_2_notes: _data.metal.internetEgress[1].notes,

        metal_egress_3_recurring: _data.metal.internetEgress[2].recurring,
        metal_egress_3_type: _data.metal.internetEgress[2].type,
        metal_egress_3_value: parseFloat(
            _data.metal.internetEgress[2].dataEgressCost,
        ),
        metal_egress_3_notes: _data.metal.internetEgress[2].notes,

        metal_egress_4_recurring: _data.metal.internetEgress[3].recurring,
        metal_egress_4_type: _data.metal.internetEgress[3].type,
        metal_egress_4_value: parseFloat(
            _data.metal.internetEgress[3].dataEgressCost,
        ),
        metal_egress_4_notes: _data.metal.internetEgress[3].notes,

        metal_egress_5_recurring: _data.metal.internetEgress[4].recurring,
        metal_egress_5_type: _data.metal.internetEgress[4].type,
        metal_egress_5_value: parseFloat(
            _data.metal.internetEgress[4].dataEgressCost,
        ),
        metal_egress_5_notes: _data.metal.internetEgress[4].notes,

        // Misc

        metal_misc_expense_1_name:
            _data.metal.miscellaneousCosts[0].expenseName, // Fixed
        metal_misc_expense_1_recurring:
            _data.metal.miscellaneousCosts[0].recurring,
        metal_misc_expense_1_type: _data.metal.miscellaneousCosts[0].type,
        metal_misc_expense_1_value: parseFloat(
            _data.metal.miscellaneousCosts[0].value,
        ),
        metal_misc_expense_1_notes: _data.metal.miscellaneousCosts[0].notes,

        metal_misc_expense_2_name:
            _data.metal.miscellaneousCosts[1].expenseName, // Fixed
        metal_misc_expense_2_recurring:
            _data.metal.miscellaneousCosts[1].recurring,
        metal_misc_expense_2_type: _data.metal.miscellaneousCosts[1].type,
        metal_misc_expense_2_value: parseFloat(
            _data.metal.miscellaneousCosts[1].value,
        ),
        metal_misc_expense_2_notes: _data.metal.miscellaneousCosts[1].notes,

        metal_misc_expense_3_name:
            _data.metal.miscellaneousCosts[2].expenseName, // Fixed
        metal_misc_expense_3_recurring:
            _data.metal.miscellaneousCosts[2].recurring,
        metal_misc_expense_3_type: _data.metal.miscellaneousCosts[2].type,
        metal_misc_expense_3_value: parseFloat(
            _data.metal.miscellaneousCosts[2].value,
        ),
        metal_misc_expense_3_notes: _data.metal.miscellaneousCosts[2].notes,
    };
};

export const coloAPIAdapter = (
    _data: CloudRebalancingTemplate & SharedInput,
): CloudRebalancingColoAPI => {
    return {
        // Expenses
        colo_expense_1_name: _data.colo.expenses[0].expenseName,
        colo_expense_1_recurring: _data.colo.expenses[0].recurring,
        colo_expense_1_type: _data.colo.expenses[0].type,
        colo_expense_1_value: parseFloat(_data.colo.expenses[0].value),

        colo_expense_2_name: _data.colo.expenses[1].expenseName,
        colo_expense_2_recurring: _data.colo.expenses[1].recurring,
        colo_expense_2_type: _data.colo.expenses[1].type,
        colo_expense_2_value: parseFloat(_data.colo.expenses[1].value),

        colo_expense_3_name: _data.colo.expenses[2].expenseName,
        colo_expense_3_recurring: _data.colo.expenses[2].recurring,
        colo_expense_3_type: _data.colo.expenses[2].type,
        colo_expense_3_value: parseFloat(_data.colo.expenses[2].value),

        colo_expense_4_name: _data.colo.expenses[3].expenseName,
        colo_expense_4_recurring: _data.colo.expenses[3].recurring,
        colo_expense_4_type: _data.colo.expenses[3].type,
        colo_expense_4_value: parseFloat(_data.colo.expenses[3].value),

        colo_expense_5_name: _data.colo.expenses[4].expenseName,
        colo_expense_5_recurring: _data.colo.expenses[4].recurring,
        colo_expense_5_type: _data.colo.expenses[4].type,
        colo_expense_5_value: parseFloat(_data.colo.expenses[4].value),

        colo_expense_6_name: _data.colo.expenses[5].expenseName,
        colo_expense_6_recurring: _data.colo.expenses[5].recurring,
        colo_expense_6_type: _data.colo.expenses[5].type,
        colo_expense_6_value: parseFloat(_data.colo.expenses[5].value),

        colo_expense_7_name: _data.colo.expenses[6].expenseName,
        colo_expense_7_recurring: _data.colo.expenses[6].recurring,
        colo_expense_7_type: _data.colo.expenses[6].type,
        colo_expense_7_value: parseFloat(_data.colo.expenses[6].value),

        colo_expense_8_name: _data.colo.expenses[7].expenseName,
        colo_expense_8_recurring: _data.colo.expenses[7].recurring,
        colo_expense_8_type: _data.colo.expenses[7].type,
        colo_expense_8_value: parseFloat(_data.colo.expenses[7].value),

        colo_expense_9_name: _data.colo.expenses[8].expenseName,
        colo_expense_9_recurring: _data.colo.expenses[8].recurring,
        colo_expense_9_type: _data.colo.expenses[8].type,
        colo_expense_9_value: parseFloat(_data.colo.expenses[8].value),

        colo_expense_10_name: _data.colo.expenses[9].expenseName,
        colo_expense_10_recurring: _data.colo.expenses[9].recurring,
        colo_expense_10_type: _data.colo.expenses[9].type,
        colo_expense_10_value: parseFloat(_data.colo.expenses[9].value),

        colo_expense_11_name: _data.colo.expenses[10].expenseName,
        colo_expense_11_recurring: _data.colo.expenses[10].recurring,
        colo_expense_11_type: _data.colo.expenses[10].type,
        colo_expense_11_value: parseFloat(_data.colo.expenses[10].value),

        colo_expense_12_name: _data.colo.expenses[11].expenseName,
        colo_expense_12_recurring: _data.colo.expenses[11].recurring,
        colo_expense_12_type: _data.colo.expenses[11].type,
        colo_expense_12_value: parseFloat(_data.colo.expenses[11].value),

        colo_expense_13_name: _data.colo.expenses[12].expenseName,
        colo_expense_13_recurring: _data.colo.expenses[12].recurring,
        colo_expense_13_type: _data.colo.expenses[12].type,
        colo_expense_13_value: parseFloat(_data.colo.expenses[12].value),

        colo_expense_14_name: _data.colo.expenses[13].expenseName,
        colo_expense_14_recurring: _data.colo.expenses[13].recurring,
        colo_expense_14_type: _data.colo.expenses[13].type,
        colo_expense_14_value: parseFloat(_data.colo.expenses[13].value),

        // Servers
        colo_server_1_vcpu: parseFloat(_data.colo.servers[0].vcpu),
        colo_server_1_memory_gb: parseFloat(_data.colo.servers[0].memory),

        colo_server_2_vcpu: parseFloat(_data.colo.servers[1].vcpu),
        colo_server_2_memory_gb: parseFloat(_data.colo.servers[1].memory),

        colo_server_3_vcpu: parseFloat(_data.colo.servers[2].vcpu),

        // FTEs
        colo_ftes_name: _data.colo.ftes[0].fteName,
        colo_ftes_value: parseFloat(_data.colo.ftes[0].annualExpense),
        colo_ftes_growth: parseFloat(_data.colo.ftes[0].annualGrowth),
        colo_ftes_number: parseFloat(_data.colo.ftes[0].quantity),

        // Internet Egress
        colo_egress_1_recurring: _data.colo.internetEgress[0].recurring,
        colo_egress_1_type: _data.colo.internetEgress[0].type,
        colo_egress_1_value: parseFloat(
            _data.colo.internetEgress[0].dataEgressCost,
        ),
        colo_egress_1_notes: _data.colo.internetEgress[0].notes,

        colo_egress_2_recurring: _data.colo.internetEgress[1].recurring,
        colo_egress_2_type: _data.colo.internetEgress[1].type,
        colo_egress_2_value: parseFloat(
            _data.colo.internetEgress[1].dataEgressCost,
        ),
        colo_egress_2_notes: _data.colo.internetEgress[1].notes,

        colo_egress_3_recurring: _data.colo.internetEgress[2].recurring,
        colo_egress_3_type: _data.colo.internetEgress[2].type,
        colo_egress_3_value: parseFloat(
            _data.colo.internetEgress[2].dataEgressCost,
        ),
        colo_egress_3_notes: _data.colo.internetEgress[2].notes,

        colo_egress_4_recurring: _data.colo.internetEgress[3].recurring,
        colo_egress_4_type: _data.colo.internetEgress[3].type,
        colo_egress_4_value: parseFloat(
            _data.colo.internetEgress[3].dataEgressCost,
        ),
        colo_egress_4_notes: _data.colo.internetEgress[3].notes,

        colo_egress_5_recurring: _data.colo.internetEgress[4].recurring,
        colo_egress_5_type: _data.colo.internetEgress[4].type,
        colo_egress_5_value: parseFloat(
            _data.colo.internetEgress[4].dataEgressCost,
        ),
        colo_egress_5_notes: _data.colo.internetEgress[4].notes,

        // Misc
        colo_misc_expense_1_name: _data.colo.miscellaneousCosts[0].expenseName, // Fixed
        colo_misc_expense_1_recurring:
            _data.colo.miscellaneousCosts[0].recurring,
        colo_misc_expense_1_type: _data.colo.miscellaneousCosts[0].type,
        colo_misc_expense_1_value: parseFloat(
            _data.colo.miscellaneousCosts[0].value,
        ),
        colo_misc_expense_1_notes: _data.colo.miscellaneousCosts[0].notes,

        colo_misc_expense_2_name: _data.colo.miscellaneousCosts[1].expenseName, // Fixed
        colo_misc_expense_2_recurring:
            _data.colo.miscellaneousCosts[1].recurring,
        colo_misc_expense_2_type: _data.colo.miscellaneousCosts[1].type,
        colo_misc_expense_2_value: parseFloat(
            _data.colo.miscellaneousCosts[1].value,
        ),
        colo_misc_expense_2_notes: _data.colo.miscellaneousCosts[1].notes,

        colo_misc_expense_3_name: _data.colo.miscellaneousCosts[2].expenseName, // Fixed
        colo_misc_expense_3_recurring:
            _data.colo.miscellaneousCosts[2].recurring,
        colo_misc_expense_3_type: _data.colo.miscellaneousCosts[2].type,
        colo_misc_expense_3_value: parseFloat(
            _data.colo.miscellaneousCosts[2].value,
        ),
        colo_misc_expense_3_notes: _data.colo.miscellaneousCosts[2].notes,
    };
};

export const cloudAPIAdapter = (
    _data: CloudRebalancingTemplate & SharedInput,
): CloudRebalancingCloudAPI => {
    return {
        // Expenses
        cloud_expense_1_name: _data.cloud.expenses[0].expenseName,
        cloud_expense_1_recurring: _data.cloud.expenses[0].recurring,
        cloud_expense_1_type: _data.cloud.expenses[0].type,
        cloud_expense_1_value: parseFloat(_data.cloud.expenses[0].value),

        cloud_expense_2_recurring: _data.cloud.expenses[1].recurring,
        cloud_expense_2_type: _data.cloud.expenses[1].type,
        cloud_expense_2_value: parseFloat(_data.cloud.expenses[1].value),

        cloud_expense_3_name: _data.cloud.expenses[2].expenseName,
        cloud_expense_3_recurring: _data.cloud.expenses[2].recurring,
        cloud_expense_3_type: _data.cloud.expenses[2].type,
        cloud_expense_3_value: parseFloat(_data.cloud.expenses[2].value),

        cloud_expense_4_name: _data.cloud.expenses[3].expenseName,
        cloud_expense_4_recurring: _data.cloud.expenses[3].recurring,
        cloud_expense_4_type: _data.cloud.expenses[3].type,
        cloud_expense_4_value: parseFloat(_data.cloud.expenses[3].value),

        // VMs
        cloud_vm_1_name: _data.cloud.vms[0].serverName,
        cloud_vm_1_vcpu: parseFloat(_data.cloud.vms[0].vcpu),
        cloud_vm_1_memory_gb: parseFloat(_data.cloud.vms[0].memory),

        // FTEs
        cloud_ftes_name: _data.cloud.ftes[0].fteName,
        cloud_ftes_value: parseFloat(_data.cloud.ftes[0].annualExpense),
        cloud_ftes_growth: parseFloat(_data.cloud.ftes[0].annualGrowth),
        cloud_ftes_number: parseFloat(_data.cloud.ftes[0].quantity),

        // Misc
        cloud_misc_expense_1_name:
            _data.cloud.miscellaneousCosts[0].expenseName, // Fixed
        cloud_misc_expense_1_recurring:
            _data.cloud.miscellaneousCosts[0].recurring,
        cloud_misc_expense_1_type: _data.cloud.miscellaneousCosts[0].type,
        cloud_misc_expense_1_value: parseFloat(
            _data.cloud.miscellaneousCosts[0].value,
        ),
        cloud_misc_expense_1_notes: _data.cloud.miscellaneousCosts[0].notes,

        cloud_misc_expense_2_name:
            _data.cloud.miscellaneousCosts[1].expenseName, // Fixed
        cloud_misc_expense_2_recurring:
            _data.cloud.miscellaneousCosts[1].recurring,
        cloud_misc_expense_2_type: _data.cloud.miscellaneousCosts[1].type,
        cloud_misc_expense_2_value: parseFloat(
            _data.cloud.miscellaneousCosts[1].value,
        ),
        cloud_misc_expense_2_notes: _data.cloud.miscellaneousCosts[1].notes,

        cloud_misc_expense_3_name:
            _data.cloud.miscellaneousCosts[2].expenseName, // Fixed
        cloud_misc_expense_3_recurring:
            _data.cloud.miscellaneousCosts[2].recurring,
        cloud_misc_expense_3_type: _data.cloud.miscellaneousCosts[2].type,
        cloud_misc_expense_3_value: parseFloat(
            _data.cloud.miscellaneousCosts[2].value,
        ),
        cloud_misc_expense_3_notes: _data.cloud.miscellaneousCosts[2].notes,
    };
};
